import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7000e7a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "change_contract_status_wrapper" }
const _hoisted_2 = { class: "change_contract_status_body" }
const _hoisted_3 = { class: "change_contract_status_title" }
const _hoisted_4 = { class: "achange_contract_status" }
const _hoisted_5 = { class: "change_contract_status_buttons_wrapper" }
const _hoisted_6 = ["disabled"]

export function render(_ctx, _cache) {
  const _component_v_select = _resolveComponent("v-select")
  const _component_v_input = _resolveComponent("v-input")
  const _component_v_textarea = _resolveComponent("v-textarea")
  const _directive_click_outside = _resolveDirective("click-outside")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('clients.view.contract_status.edit_contract_status')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_v_select, {
          class: "mt-4 mb-4",
          modelValue: _ctx.newStatus,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = $event => ((_ctx.newStatus) = $event)),
            _cache[1] || (_cache[1] = $event => {_ctx.freezeStartDateErrorText = ''; _ctx.freezeEndDateErrorText = ''; _ctx.terminateDateErrorText = ''; _ctx.terminateReasonErrorText = ''; _ctx.commentErrorText = ''})
          ],
          label: 'clients.view.contract_status.new_contract_status',
          items: _ctx.newContractStatuses
        }, null, 8, ["modelValue", "label", "items"]),
        _withDirectives(_createElementVNode("div", null, [
          _createVNode(_component_v_input, {
            class: "mb-4",
            modelValue: _ctx.freezeStartDate,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.freezeStartDate) = $event)),
            inputType: "date",
            label: _ctx.$t('clients.view.contract_status.freeze_start_date'),
            height: "22",
            errorText: _ctx.freezeStartDateErrorText,
            min: _ctx.minFreezeStartDate,
            max: _ctx.maxFreezeStartDate,
            onFocus: _cache[3] || (_cache[3] = $event => (_ctx.freezeStartDateErrorText = '')),
            clearable: ""
          }, null, 8, ["modelValue", "label", "errorText", "min", "max"]),
          _createVNode(_component_v_input, {
            class: "mb-4",
            modelValue: _ctx.freezeEndDate,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.freezeEndDate) = $event)),
            inputType: "date",
            label: _ctx.$t('clients.view.contract_status.freeze_end_date'),
            height: "22",
            errorText: _ctx.freezeEndDateErrorText,
            min: _ctx.minFreezeEndDate,
            max: _ctx.maxFreezeEndDate,
            onFocus: _cache[5] || (_cache[5] = $event => (_ctx.freezeEndDateErrorText = '')),
            clearable: ""
          }, null, 8, ["modelValue", "label", "errorText", "min", "max"])
        ], 512), [
          [_vShow, _ctx.newStatus?.id == 2]
        ]),
        _withDirectives(_createElementVNode("div", null, [
          (!_ctx.canTerminateOnlyOnCurrentDay)
            ? (_openBlock(), _createBlock(_component_v_input, {
                key: 0,
                class: "mb-4",
                modelValue: _ctx.terminateDate,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.terminateDate) = $event)),
                inputType: "date",
                label: _ctx.$t('clients.view.contract_status.terminate_date'),
                height: "22",
                errorText: _ctx.terminateDateErrorText,
                min: _ctx.minFTerminationDate,
                onFocus: _cache[7] || (_cache[7] = $event => (_ctx.terminateDateErrorText = '')),
                clearable: ""
              }, null, 8, ["modelValue", "label", "errorText", "min"]))
            : _createCommentVNode("", true),
          (_ctx.terminateReasons.length > 0)
            ? (_openBlock(), _createBlock(_component_v_select, {
                key: 1,
                class: "mb-4",
                modelValue: _ctx.terminateReason,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.terminateReason) = $event)),
                label: 'clients.view.contract_status.terminate_reasone',
                items: _ctx.terminateReasons,
                errorText: _ctx.terminateReasonErrorText,
                onClick: _cache[9] || (_cache[9] = $event => (_ctx.terminateReasonErrorText = ''))
              }, null, 8, ["modelValue", "label", "items", "errorText"]))
            : _createCommentVNode("", true)
        ], 512), [
          [_vShow, _ctx.newStatus?.id == 6]
        ]),
        _createVNode(_component_v_textarea, {
          modelValue: _ctx.comment,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.comment) = $event)),
          label: _ctx.$t('clients.view.contract_status.comment'),
          errorText: _ctx.commentErrorText,
          onFocus: _cache[11] || (_cache[11] = $event => (_ctx.commentErrorText = ''))
        }, null, 8, ["modelValue", "label", "errorText"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          class: "primary_button",
          onClick: _cache[12] || (_cache[12] = (...args) => (_ctx.save && _ctx.save(...args))),
          disabled: _ctx.isButtonDisabled
        }, _toDisplayString(_ctx.$t('clients.view.contract_status.save')), 9, _hoisted_6),
        _createElementVNode("button", {
          class: "secondary_button button_space",
          onClick: _cache[13] || (_cache[13] = $event => (_ctx.$emit('close')))
        }, _toDisplayString(_ctx.$t('clients.view.contract_status.cancel')), 1)
      ])
    ])), [
      [_directive_click_outside, _ctx.close]
    ])
  ]))
}