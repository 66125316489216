<div class="view_lead_wrapper">
    <div class="view_lead_body" v-click-outside="close">
        <div class="view_lead_body_title">
            <div class="client_photo" :style="{ 'background-image': `url(${photoUrl})` }"></div>
            <div class="client_title_wrapper">
                <p class="client_name" :class="{client_redirect: lead?.client}" @click="clickOnRedirect(lead?.leadId)">{{lead?.client ? `${lead.client.lastName} ${lead.client.firstName}` : name }}</p>
                <p class="client_phone">{{lead?.client ? addPhoneMask(lead.client.phone) : addPhoneMask(lead?.phone)}}</p>
            </div>
        </div>
        <div class="view_lead_content_wrapper" v-if="!isLoading">
            <div class="select_block">
                <div class="select_wrapper">
                    <button :class="isShowInfo ? 'select_button_active' : 'select_button'" @click="isShowInfo = true">{{$t('task.view.info')}}</button>
                    <button :class="isShowInfo ? 'select_button' : 'select_button_active'" @click="isShowInfo = false">{{$t('task.view.history')}}</button>
                </div>
            </div>


            <div class="view_lead" :class="{ mobile_hide: !isShowInfo }">
                <div class="view_lead_info">

                    <div class="three_block_wrapper" v-if="!lead?.client">
                        <div class="two_block_wrapper mt-1">
                            <div class="left_block mb-4"><v-input v-model="name" :label="$t('task.view.name')" height="22" :errorText="nameErrorText" @focus="nameErrorText = ''" clearable></v-input></div>
                            <div class="right_block mb-4"><v-select class="w-100" v-model="selectedSex" :label="'task.view.sex'" :items="sex"></v-select></div>
                        </div>
                        <div class="w-100 mb-4"><v-input v-model="mail" :label="$t('task.view.mail')" height="22" clearable></v-input></div>
                    </div>


                    <div v-if="lead?.client">
                        <div class="client_info_wrapper">
                            <div class="mb-3 client_info">
                                <p class="block_label">{{$t('task.view.mail')}}</p>
                                <p class="block_info">{{lead.client.email || $t('task.view.absent')}}</p>
                            </div>
                            <div class="mb-3 client_info">
                                <p class="block_label">{{$t('task.view.birthday')}}</p>
                                <p class="block_info">{{getFormatedDate(lead.client.birthday, "DD MMMM yyyy") || $t('task.view.absent')}}</p>
                            </div>
                            <div class="mb-3 client_info">
                                <p class="block_label">{{$t('task.view.sex')}}</p>
                                <p class="block_info">{{lead.client.sex ? $t('clients.male') : $t('clients.female') }}</p>
                            </div>

                            <div class="mb-3 client_info">
                                <p class="block_label">{{$t('task.view.stay')}}</p>
                                <div class="flex"><div :class="lead.client.inClub ? 'dot dot_active' : 'dot dot_disable'"></div><p class="in_club_status">{{lead.client.isInClub ? $t('task.view.in_club') : $t('task.view.not_in_club')}}</p></div>
                            </div>
                            <div class="mb-3 client_info">
                                <p class="block_label">{{$t('task.view.last_in')}}</p>
                                <p class="block_info">{{getFormatedDate(lead.client.lastInDate, "DD.MM.yyyy HH:mm") || '-' }}</p>
                            </div>
                            <div class="mb-3 client_info">
                                <p class="block_label">{{$t('task.view.last_out')}}</p>
                                <p class="block_info">{{getFormatedDate(lead.client.lastOutDate, "DD.MM.yyyy HH:mm") || '-'}}</p>
                            </div>

                        </div>

                        <div class="contract_wrapper" v-if="lead.client.contracts?.length > 0">
                            <div class="contract_title flex">
                                <div class="w-100">
                                    <p class="agreement">{{lead.client.contracts[currentContractIndex].agreement}}</p>
                                    <div class="flex">
                                        <p class="contract">{{lead.client.contracts[currentContractIndex].contract}}</p>
                                        <div class="flex contract_status"><div :class="'dot dot_contract_' + getContractStatus(lead.client?.contracts[currentContractIndex].status)"></div>{{ lead?.client ? $t('contract_status.' + getContractStatus(lead.client?.contracts[currentContractIndex].status)) : ""}}</div>
                                    </div>
                                </div>
                                <div class="flex" v-if="lead.client.contracts.length > 1">
                                    <button @click="previousContact" :class="currentContractIndex != 0 ? 'contract_switch_button mr-2' : 'contract_switch_button contract_switch_button_disabled mr-2'"><img class="contract_switch_button_img" src="/assets/img/arrow-left.svg"></button>
                                    <p class="contract_switch_text">{{currentContractIndex + 1}}/{{lead.client?.contracts.length}}</p>
                                    <button @click="nextContact" :class="currentContractIndex != lead.client?.contracts.length - 1 ? 'contract_switch_button ml-2' : 'contract_switch_button contract_switch_button_disabled ml-2'"><img class="contract_switch_button_img" src="/assets/img/arrow-right.svg"></button>
                                </div>
                            </div>

                            <div class="contract_info">
                                <div>

                                    <div class="two_block_wrapper">
                                        <div class="left_block mb-3">
                                            <p class="block_label">{{$t('task.view.start_date')}}</p>
                                            <p class="block_info">{{ getFormatedDate(lead.client.contracts[currentContractIndex].startDate, "DD.MM.yyyy") || '-' }}</p>
                                        </div>
                                        <div class="right_block mb-3">
                                            <p class="block_label">{{$t('task.view.end_date')}}</p>
                                            <p class="block_info">{{ getFormatedDate(lead.client.contracts[currentContractIndex].endDate, "DD.MM.yyyy") || '-' }}</p>
                                        </div>
                                    </div>

                                    <div class="two_block_wrapper">
                                        <div class="left_block mb-3">
                                            <p class="block_label">{{$t('task.view.promocode')}}</p>
                                            <p class="block_info">{{ lead.client.contracts[currentContractIndex].referralPromocode }}</p>
                                        </div>
                                        <div class="right_block mb-3">
                                            <p class="block_label">{{$t('task.view.freeze')}}</p>
                                            <p class="block_info">{{ $t('task.view.available_days')}}: {{lead.client.contracts[currentContractIndex].availableFreezingDaysNumber}}</p>
                                        </div>
                                    </div>

                                    <div class="two_block_wrapper">
                                        <div class="left_block mb-3">
                                            <p class="block_label">{{$t('task.view.previous_payment')}}</p>
                                            <div class="flex" v-if="lead.client.contracts[currentContractIndex].previousPaymentDueDate"><p class="block_info ">{{getFormatedAmount(lead.client.contracts[currentContractIndex].previousPaymentTotal) }} {{lead.client.contracts[currentContractIndex].currencySign }} <span class="contract_payment_date">{{ getFormatedDate(lead.client.contracts[currentContractIndex].previousPaymentDueDate, "DD.MM.yyyy") || '-' }}</span></p></div>
                                            <div class="flex" v-else><p class="block_info ">-</p></div>
                                        </div>
                                        <div class="right_block mb-3">
                                            <p class="block_label">{{$t('task.view.next_payment')}}</p>
                                            <div class="flex" v-if="lead.client.contracts[currentContractIndex].nextPaymentDueDate"><p class="block_info ">{{getFormatedAmount(lead.client.contracts[currentContractIndex].nextPaymentTotal) }} {{lead.client.contracts[currentContractIndex].currencySign }} <span class="contract_payment_date">{{ getFormatedDate(lead.client.contracts[currentContractIndex].nextPaymentDueDate, "DD.MM.yyyy") || '-' }}</span></p></div>
                                            <div class="flex" v-else><p class="block_info ">-</p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mb-3 pl-1" v-if="lead?.openingReason">
                        <p class="block_label">{{$t('task.view.opening_reason')}}</p>
                        <p class="block_info">{{ lead?.openingReason }}</p>
                    </div>

                    <div class="mb-3" v-if="clubs.length > 1">
                        <div class="mb-4"><v-select class="w-100" v-model="selectedClub" :label="'task.view.club'" :items="clubs"></v-select></div>
                    </div>

                    <div class="two_block_wrapper">
                        <div class="left_block mb-4"><v-select class="w-100" v-model="selectedStatus" :label="'task.view.status'" :items="statuses" clearable></v-select></div>
                        <div class="right_block mb-4"><v-select class="w-100" v-model="selectedManagers" :label="'task.view.manager'" :items="managers" clearable></v-select></div>
                    </div>

                    <div class="two_block_wrapper">
                        <div class="left_block mb-4"><v-select class="w-100" v-model="selectedNextStep" :label="'task.view.next_step'" :items="nextSteps" clearable></v-select></div>
                        <div class="right_block mb-4">
                            <div class="position-relative">
                                <v-input v-model="date" :label="$t('task.view.date')" :inputType="'datetime-local'" height="22" :errorText="dateErrorText" @focus="dateErrorText = ''" clearable></v-input>
                                <button class="now_button" @click="setNowTime"><img class="" src="/assets/img/clock.svg"></button>
                            </div>
                           
                        </div>
                    </div>

                    <v-textarea v-model="message" :label="$t('task.view.comment')"></v-textarea>

                </div>
                <div class="view_lead_buttons_wrapper">
                    <button class="primary_button" @click="save" :disabled="isButtonDisabled">{{$t('task.view.save') }}</button>
                    <button class="secondary_button button_space" @click="$emit('close')">{{$t('task.view.cancel')}}</button>
                </div>

            </div>

            <div class="view_lead_history" :class="{ mobile_hide: isShowInfo }">
                <div class="view_lead_history_title"> {{$t('task.view.history') }}</div>

                <div :class="historyWrapperClass">
                    <div v-for="leadHistory in lead?.leadHistories" class="history">
                        <div class="history_title">
                            <div class="flex">
                                <div class="history_dot"></div>
                                <div class="history_date">{{getFormatedDate(leadHistory.createDate, "DD.MM.yyyy HH:mm")}}</div>

                            </div>
                            <div class="history_event_type">{{$t(leadHistory.eventType)}}</div>
                        </div>

                        <div class="history_user" v-show="leadHistory?.userName">{{leadHistory?.userName}}</div>

                        <div class="history_description" v-if="leadHistory.newValue">
                            <audio class="audio" v-if="canPlayMp3 && (leadHistory.eventTypeId == 5 || leadHistory.eventTypeId == 6)" controls="controls" preload="none"><source :src="leadHistory.additionalData" type="audio/mpeg" /></audio>
                            <a class="download_audio" v-if="!canPlayMp3 && (leadHistory.eventTypeId == 5 || leadHistory.eventTypeId == 6)" :href="leadHistory.additionalData" download>{{$t('task.view.download_call')}}</a>
                            <p v-if="leadHistory.eventTypeId != 5 && leadHistory.eventTypeId != 6" class="m-0">{{leadHistory.newValue}}</p>
                        </div>
                    </div>
                </div>

            </div>


        </div>

        <div class="view_lead_content_wrapper" v-if="isLoading">
            <p class="loading">{{$t('task.view.loading')}}</p>
        </div>
    </div>
</div>

