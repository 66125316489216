<div class="change_contract_status_wrapper">
    <div class="change_contract_status_body" v-click-outside="close">
        <div class="change_contract_status_title">{{$t('clients.view.contract_status.edit_contract_status')}}</div>
        <div class="achange_contract_status">
            <v-select class="mt-4 mb-4" v-model="newStatus" :label="'clients.view.contract_status.new_contract_status'" :items="newContractStatuses"  @update:modelValue="freezeStartDateErrorText = ''; freezeEndDateErrorText = ''; terminateDateErrorText = ''; terminateReasonErrorText = ''; commentErrorText = ''"></v-select>

            <div v-show="newStatus?.id == 2">
                <v-input class="mb-4" v-model="freezeStartDate" inputType="date" :label="$t('clients.view.contract_status.freeze_start_date')" height="22" :errorText="freezeStartDateErrorText" :min="minFreezeStartDate" :max="maxFreezeStartDate" @focus="freezeStartDateErrorText = ''" clearable></v-input>
                <v-input class="mb-4" v-model="freezeEndDate" inputType="date" :label="$t('clients.view.contract_status.freeze_end_date')" height="22" :errorText="freezeEndDateErrorText" :min="minFreezeEndDate" :max="maxFreezeEndDate" @focus="freezeEndDateErrorText = ''" clearable></v-input>
            </div>

            <div v-show="newStatus?.id == 6">
                <v-input v-if="!canTerminateOnlyOnCurrentDay" class="mb-4" v-model="terminateDate" inputType="date" :label="$t('clients.view.contract_status.terminate_date')" height="22" :errorText="terminateDateErrorText" :min="minFTerminationDate" @focus="terminateDateErrorText = ''" clearable></v-input>
                <v-select class="mb-4" v-model="terminateReason" :label="'clients.view.contract_status.terminate_reasone'" :items="terminateReasons" v-if="terminateReasons.length > 0" :errorText="terminateReasonErrorText"  @click="terminateReasonErrorText = ''"></v-select>
            </div>

            <v-textarea v-model="comment" :label="$t('clients.view.contract_status.comment')" :errorText="commentErrorText" @focus="commentErrorText = ''"></v-textarea>

        </div>
        <div class="change_contract_status_buttons_wrapper">
            <button class="primary_button" @click="save" :disabled="isButtonDisabled">{{$t('clients.view.contract_status.save') }}</button>
            <button class="secondary_button button_space" @click="$emit('close')">{{$t('clients.view.contract_status.cancel')}}</button>
        </div>
    </div>
</div>

